var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("vue-headful", { attrs: { title: _vm.pageTitle } }),
      _c(
        "div",
        {
          staticClass: "has-text-centered has-background-primary",
          staticStyle: { "margin-bottom": "20px" },
        },
        [
          _c(
            "h1",
            {
              staticClass: "is-size-6 has-text-white",
              staticStyle: { padding: "5px 0px" },
            },
            [_vm._v(_vm._s(_vm.pageheading.toLocaleUpperCase()))]
          ),
        ]
      ),
      _c("div", { staticStyle: { "max-width": "95%", margin: "auto" } }, [
        _vm.isLoading
          ? _c("div", [_c("Loading")], 1)
          : _c("div", { attrs: { id: "body-content-area" } }, [
              _c("div", { attrs: { id: "pagelayout" } }, [
                _c(
                  "div",
                  {
                    staticStyle: {
                      display: "flex",
                      "justify-content": "flex-end",
                    },
                    attrs: { id: "buttongroup" },
                  },
                  [
                    _c("div", { staticClass: "field is-grouped" }, [
                      _c("div", { staticClass: "control" }, [
                        _c(
                          "button",
                          {
                            staticClass: "button is-accent has-text-white",
                            attrs: {
                              disabled:
                                !_vm.$hasPermissions(
                                  _vm.clientSession,
                                  ["REPORT_TYPES"],
                                  2
                                ) || _vm.isSaveDisabled,
                              type: "submit",
                            },
                            on: { click: _vm.handleStatesSubmit },
                          },
                          [_vm._v("Save")]
                        ),
                      ]),
                      _c("div", { staticClass: "control" }, [
                        _c(
                          "a",
                          {
                            staticClass: "button is-light",
                            on: {
                              click: function ($event) {
                                _vm.resetForm()
                                _vm.$router.go(-1)
                              },
                            },
                          },
                          [_vm._v("Cancel")]
                        ),
                      ]),
                    ]),
                  ]
                ),
                _c("div", { staticClass: "columns" }, [
                  _c("div", { staticClass: "column" }, [
                    _c("div", { staticClass: "block" }, [
                      _c(
                        "form",
                        {
                          on: {
                            submit: function ($event) {
                              $event.preventDefault()
                              return _vm.handleStatesSubmit()
                            },
                          },
                        },
                        [
                          _c("div", [
                            _c("div", { staticClass: "field" }, [
                              _vm._m(0),
                              _c("div", { staticClass: "control" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.input.StateAbbreviation,
                                      expression: "input.StateAbbreviation",
                                    },
                                  ],
                                  class: {
                                    input: true,
                                    "is-danger": this.errors.code,
                                  },
                                  attrs: {
                                    type: "text",
                                    name: "typecode",
                                    disabled: !_vm.isCreateMode,
                                  },
                                  domProps: {
                                    value: _vm.input.StateAbbreviation,
                                  },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.input,
                                        "StateAbbreviation",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                              ]),
                              _c(
                                "small",
                                { staticStyle: { color: "#eaae5e!important" } },
                                [
                                  _vm._v(
                                    "State Code cannot be modified after saving the state configuration."
                                  ),
                                ]
                              ),
                            ]),
                            _c("div", { staticClass: "field" }, [
                              _vm._m(1),
                              _c("div", { staticClass: "control" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.input.StateName,
                                      expression: "input.StateName",
                                    },
                                  ],
                                  class: {
                                    input: true,
                                    "is-danger": this.errors.name,
                                  },
                                  attrs: { type: "text", name: "name" },
                                  domProps: { value: _vm.input.StateName },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.input,
                                        "StateName",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                              ]),
                            ]),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "column" }),
                ]),
              ]),
            ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "label is-size-7" }, [
      _vm._v(" State Code "),
      _c(
        "span",
        {
          staticClass: "has-text-danger",
          staticStyle: { "padding-left": "3px" },
        },
        [_vm._v("*")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "label is-size-7" }, [
      _vm._v(" State Name "),
      _c(
        "span",
        {
          staticClass: "has-text-danger",
          staticStyle: { "padding-left": "3px" },
        },
        [_vm._v("*")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }